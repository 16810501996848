import React, { PureComponent, useState, useCallback, useEffect } from "react";
import CountDownBlock from "./CountDownBlock";

import Link from "./LinkWithTransitionLock"
import InViewObserver from "./InViewObserver"
import isMobile from "react-device-detect"
import { Expo, Power4, gsap } from 'gsap';


const Post_Item = props => (
  <li className="post_item">
    <Link to={props.link} className="post_label" data-visual={props.visual}>
      <h6 className="post_sub_title">{props.sub_title}</h6>
      <h5 className="post_main_title">{props.main_title}</h5>
    </Link>
  </li>
)

const Ｍedium_Item = props => (
  <li className="post_item">
    <a href={props.link} className="post_label" target="_blank">
      <h5 className="post_main_title">{props.main_title}</h5>
      <h6 className="post_sub_title">{props.sub_title}</h6>
      <p className="post_bio">{props.bio}</p>
    </a>
  </li>
)

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    
    InViewObserver()


    var link = document.querySelectorAll(".post_item");
    var cursor = document.querySelector(".cursor");
    var visual = document.querySelector(".post_visual_bg");
    var visual_inner = document.querySelector(".bg_inner");
    var group_post_list = document.querySelectorAll(".group_post_list");
    

    var num = Math.floor(Math.random() * 10) + 1;
    num *= Math.floor(Math.random() * 2) == 1 ? 1 : -1;


    const hero_link_animate = function(e) {
      var target = this.querySelector(".post_label");
      var target_value = target.dataset.visual;

      visual_inner.style.backgroundImage = `url(${target_value})`;
      visual.classList.add("in");
      visual.classList.remove("out");

      // group_post_list.classList.add('currenting');
      target.classList.add('currenting');
      group_post_list.forEach((b) => b.classList.add('currenting'));

      // var num = Math.floor(Math.random() * 9) + 1;
      // num *= Math.floor(Math.random() * 1.5) == 1 ? 1 : -1;
      // gsap.to(visual, {
      //   opacity: 1,
      //   duration: .4,
      //   ease: Power4.easeIn
      // });

    };


    const hero_link_animate_2 = function(e) {
      var target = this.querySelector(".post_label");
      var target_value = target.dataset.visual;

      // target.style.transform = `translate(0px, 0px) scale(1)`;
      visual.classList.remove("in");
      visual.classList.add("out");

      group_post_list.forEach((b) => b.classList.remove('currenting'));
      target.classList.remove('currenting');

      var num = Math.floor(Math.random() * 10) + 1;
      num *= Math.floor(Math.random() * 2) == 1 ? 1 : -1;

      setTimeout(() => {
        visual.classList.remove("out");
        // visual.style.transform = `rotate(${num}deg)`;
      }, 400);
      // gsap.to(visual, {
      //   opacity: 0,
      //   duration: .4,
      //   ease: Power4.easeIn,
      //   onComplete:function(){
      //     var num = Math.floor(Math.random() * 9) + 1;
      //     num *= Math.floor(Math.random() * 1.5) == 1 ? 1 : -1;
      //     gsap.set(visual, {
      //       rotate: num + 'deg'
      //     })
      //   }
      // });

    };

    // if ( isMobile ) {
      // link.forEach((b) => b.addEventListener("touchmove", hero_link_animate));
      link.forEach((b) => b.addEventListener("touchstart", hero_link_animate));
      link.forEach((b) => b.addEventListener("touchend", hero_link_animate_2));
    // } else {
      link.forEach((b) => b.addEventListener("mousemove", hero_link_animate));
      link.forEach((b) => b.addEventListener("mouseleave", hero_link_animate_2));
      visual.style.height = window.innerHeight + 'px';
    // }


  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap blog_wrap">

        <div className="page_header">
          <h2 className="sub_title">社群擾動</h2>
          <h1 className="main_title">對話</h1>
          {/* <h6 className="speaker">策展人/方彥翔</h6> */}
        </div>

        <div className="page_content blog_content">

          <div className="blog_line">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15.51px" height="1070px" viewBox="0 0 15.51 1070" preserveAspectRatio="none">
              <g>
                {/* <path d="M6.11,8.48c-6.54,72.14-7.32,138.23,0.7,195.09c11.87,51.04,8.47,114.39,0,181.83 C-0.88,437.26-1,487.16,6.81,535c10.69,58.28,9.79,121.03,0,187.21c-7.92,49.36-7.58,98.58,0,147.66 c10.94,58.33,9.37,124.11,0.63,194.16"/> */}
                <line class="st0" x1="1" y1="1069" x2="1" y2="1"/>
              </g>
            </svg>
          </div>

          <div className="blog_content_wrap">

            <div className="content_group">
              <div className="group_sub_title">
                <div className="dot main"></div>
                <div className="text_inner">前期活動</div>
              </div>
              <div className="group_head context_s">
                <h3 className="main_title">20 X 20 - 社宅公共藝術的 N+1 種想像</h3>
                <div className="summary">
                  <p>社會住宅的公共藝術即將開始啟動，在各類藝術計畫啟動之前，我們期許能擴大各種想像以及討論，擾動藝文工作者對於參與社宅公共藝術的認知與期待，鋪陳日後與藝術家在實際實踐場域的互動與參與，打開社宅公共藝術的N+1種想像！</p>
                </div>
              </div>
              <ul className="group_post_list">
                <Post_Item link="/blog/post_1_1" sub_title="上半場" main_title="沈伯丞" visual={require("../images/blog/post_1_1.jpg")} />
                <Post_Item link="/blog/post_1_2" sub_title="上半場" main_title="林長叡" visual={require("../images/blog/post_1_2.jpg")} />
                <Post_Item link="/blog/post_1_3" sub_title="上半場" main_title="鄭鴻旗" visual={require("../images/blog/post_1_3.jpg")} />
                <Post_Item link="/blog/post_1_4" sub_title="上半場" main_title="賴映如" visual={require("../images/blog/post_1_4.jpg")} />
                <Post_Item link="/blog/post_1_5" sub_title="上半場" main_title="彭永翔" visual={require("../images/blog/post_1_5.jpg")} />
                <Post_Item link="/blog/post_1_6" sub_title="上半場" main_title="主持人評論與講者回應" visual={require("../images/blog/post_1_6.jpg")} />
                <Post_Item link="/blog/post_1_7" sub_title="下半場" main_title="黃姍姍" visual={require("../images/blog/post_1_7.jpg")} />
                <Post_Item link="/blog/post_1_8" sub_title="下半場" main_title="李萬鏗" visual={require("../images/blog/post_1_8.jpg")} />
                <Post_Item link="/blog/post_1_9" sub_title="下半場" main_title="蔡淳任" visual={require("../images/blog/post_1_9.jpg")} />
                <Post_Item link="/blog/post_1_10" sub_title="下半場" main_title="方彥翔" visual={require("../images/blog/post_1_10.jpg")} />
                <Post_Item link="/blog/post_1_11" sub_title="下半場" main_title="孫育晴" visual={require("../images/blog/post_1_11.jpg")} />
                <Post_Item link="/blog/post_1_12" sub_title="下半場" main_title="主持人評論與討論回應" visual={require("../images/blog/post_1_6.jpg")} />
              </ul>
            </div>

            <div className="content_group">
              <div className="group_sub_title">
                <div className="dot sub"></div>
                {/* <div className="text_inner">前期活動</div> */}
              </div>
              <div className="group_head context_s">
                <h3 className="main_title">社宅公共藝術咖啡桌</h3>
                <div className="summary">
                  <p>公共藝術離開美術館、畫廊，進入都市中各種真實生活場景實踐，面對不同背景、階級、需求的人們，常被賦予各種不同的任務以及使命。在台北市社宅的社會運動之中，被專業界期許扮演橋樑，以凝聚社區意識、作為當地社區的溝通觸媒。</p>
                </div>
              </div>
              <ul className="group_post_list">
                <Post_Item link="/blog/post_2_1" sub_title="第一輪" main_title="傳承創新" visual={require("../images/blog/post_2_1_1.jpg")} />
                <Post_Item link="/blog/post_2_2" sub_title="第一輪" main_title="公民社會" visual={require("../images/blog/post_2_2_1.jpg")} />
                <Post_Item link="/blog/post_2_3" sub_title="第一輪" main_title="機制執行" visual={require("../images/blog/post_2_3_1.jpg")} />
                <Post_Item link="/blog/post_2_4" sub_title="第一輪" main_title="城市策略" visual={require("../images/blog/post_2_4_1.jpg")} />
                <Post_Item link="/blog/post_2_5" sub_title="第一輪" main_title="回饋與討論" visual={require("../images/blog/post_2_5.jpg")} />
                <Post_Item link="/blog/post_2_6" sub_title="第二輪" main_title="傳承創新" visual={require("../images/blog/post_2_6_1.jpg")} />
                <Post_Item link="/blog/post_2_7" sub_title="第二輪" main_title="公民社會" visual={require("../images/blog/post_2_7_1.jpg")} />
                <Post_Item link="/blog/post_2_8" sub_title="第二輪" main_title="機制執行" visual={require("../images/blog/post_2_8_1.jpg")} />
                <Post_Item link="/blog/post_2_9" sub_title="第二輪" main_title="城市策略" visual={require("../images/blog/post_2_9_1.jpg")} />
                <Post_Item link="/blog/post_2_10" sub_title="第二輪" main_title="回饋與討論" visual={require("../images/blog/post_2_10.jpg")} />
              </ul>
            </div>

            <div className="content_group">
              <div className="group_sub_title">
                <div className="dot sub"></div>
                {/* <div className="text_inner">前期活動</div> */}
              </div>
              <div className="group_head context_s">
                <h3 className="main_title">藝術與空間的碰撞</h3>
                <div className="summary">
                  <p>藝術與建築設計皆為美學重要支柱，思維與訓練卻可能有所異同。公共藝術理想上應該是建築與藝術的交融對話，然而長期彼此難以整合，在實務上總是無法並肩齊驅。為期許社宅公共藝術能跳脫過去的框架，我們希望促進建築與藝術界的彼此傾聽，讓不同的美學思維可能在社宅的公共空間互相交流。</p>
                </div>
              </div>
              <ul className="group_post_list">
                <Post_Item link="/blog/post_3_1" sub_title="社宅公共藝術未來式" main_title="郭恆成" visual={require("../images/blog/post_3_1.jpg")} />
                <Post_Item link="/blog/post_3_2" sub_title="社宅公共藝術進行式" main_title="戴嘉惠" visual={require("../images/blog/post_3_2.jpg")} />
                <Post_Item link="/blog/post_3_3" sub_title="藝術家創作經驗分享" main_title="張耿華" visual={require("../images/blog/post_3_3.jpg")} />
              </ul>
            </div>


            <div className="content_group">
              <div className="group_sub_title">
                <div className="dot main"></div>
                <div className="text_inner">展覽活動</div>
              </div>
              <div className="group_head context_s">
                <h3 className="main_title">文章接龍</h3>
                <div className="summary">
                  <p>配合這個階段的社宅公共藝術概念實驗展覽，我們邀請台灣青壯輩重要的藝文工作者，一同參與這場社宅公共藝術的革新，激盪各種關於藝術+社宅的行動想像。</p>
                  <p>線上展覽將會把撰文專家之介紹連動至Ｍedium讓觀者有更好的閱覽體驗，為喚起各界關注，鼓動社群的線上互動，專家學者們撰寫「社宅公共藝術」短篇文章，並在本計畫的FB粉絲專頁上呈現各位帶狀的以文章接續TAG接力的趣味互動。</p>
                </div>
              </div>
              <ul className="group_post_list">
                <Ｍedium_Item link="https://bit.ly/2VFRv1c" sub_title="@陳彥良" bio="景觀設計師 / 台北青年事務委員會委員" main_title="10%的力量＋1%的藝術＝臺北新社宅美學" />
                <Ｍedium_Item link="https://bit.ly/2VKnjC5" sub_title="@陳宣誠" bio="建築師、策展人、藝術顧問" main_title="我們如何一起生活" />
                <Ｍedium_Item link="https://bit.ly/2VMqhGi" sub_title="@吳書原" bio="景觀設計專家" main_title="Landscape as Art" />
                <Ｍedium_Item link="https://bit.ly/3fhWmxq" sub_title="@朱文英" bio="燈光設計師" main_title="用光看見美好，淺談公共藝術與光" />
                <Ｍedium_Item link="https://bit.ly/2W8zKrK" sub_title="@程詩郁" bio="左腦創意行銷負責人" main_title="社宅的公共藝術：不怕衝突，創造對話可能" />
                <Ｍedium_Item link="https://bit.ly/3cjeFAk" sub_title="@劉真蓉" bio="白晝之夜、文博會策展人" main_title="(無標題)" />
                <Ｍedium_Item link="https://bit.ly/3dynnL9" sub_title="@康旻杰" bio="台大建築與城鄉研究所" main_title="公共藝術的社會想像" />
                <Ｍedium_Item link="https://medium.com/@hometoall/%E5%85%AC%E5%85%B1%E4%BA%86%E4%BB%80%E9%BA%BC-%E4%BA%8C-%E7%A4%BE%E5%AE%85%E5%85%AC%E5%85%B1%E8%97%9D%E8%A1%93-%E4%B8%80%E5%80%8B%E6%96%B0%E9%9A%8E%E6%AE%B5%E7%9A%84%E5%B8%82%E6%B0%91%E7%A4%BE%E6%9C%83%E5%BB%BA%E5%88%B6%E8%A1%8C%E5%8B%95-%E5%B0%8D-home-to-all-%E7%9A%84%E5%9B%9E%E6%87%89-2bba2a0f3f27" sub_title="@邱俊達" bio="臺灣藝術檔案中心研究員 / 獨立策展人" main_title="公共了什麼？（二）社宅公共藝術：一個新階段的市民社會建制行動：對「Home To All」的回應" />
                {/* 
                <Ｍedium_Item link="" sub_title="@劉真蓉" bio="" main_title="" />
                <Ｍedium_Item link="" sub_title="@蘇民" bio="" main_title="" />
                <Ｍedium_Item link="" sub_title="@邱彥達" bio="" main_title="" />
                <Ｍedium_Item link="" sub_title="@康旻杰" bio="" main_title="" /> */}
              </ul>
            </div>

            <div className="content_group">
              <div className="group_sub_title">
                <div className="dot main"></div>
              </div>
              <div className="group_head context_s">
                <h3 className="main_title">媒體座談</h3>
                <div className="summary">
                  <p><Link to="/blog/post_4">了解座談精華</Link></p>
                </div>
              </div>
            </div>

            <div className="content_group">
              <div className="group_sub_title">
                <div className="dot main"></div>
              </div>
              <div className="group_head context_s">
                <h3 className="main_title">社宅公共藝術計劃命名活動</h3>
                <div className="summary">
                  <h5><i>LOGIN@_</i></h5>
                  <h5><i>登入台北 / 家入台北</i></h5>
                  <br/>
                  <p><Link to="/blog/post_5">了解　LOGIN@　</Link></p>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div className="page_footer">
          <Link to="/project_a" className="next_page">
            <div className="next_title">成為我的繆思，獻給他的禮物</div>
            <div className="next_owner">策展人/沈伯丞</div>
          </Link>
        </div>

        <div className="post_visual_bg">
          <div className="bg_inner"></div>
        </div>

      </div>

      // <CountDownBlock blockType="comingSoon" targetDate={new Date(2020, 4, 27)}>
      //   <h4>即將登場</h4>
      // </CountDownBlock>
    );
  }

}


export default Page
